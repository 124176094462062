
export const BookingSummary = (props) =>{
 
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
  
    return  <div>
        {props.summary.view === "lg" ? <div style={{"backgroundColor":"#1d4028","padding":"20px","color":"white","boxShadow":"rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"}} className="booking-sub-section d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        <h5 style={{"color":"white"}}><b>Reservation Summary</b></h5>
                            <hr/>
                            <span><b>Room Name:</b> {props.summary.category}</span>
                <br/><br/>
                <span><b>Day Time Price:</b> {props.summary.price.price1 == null ? 0 : Naira.format(props.summary.price.price1)}</span>
                <br/><br/>
                <span><b>Overnight Price:</b> {props.summary.price.price2 == null ? 0 : Naira.format(props.summary.price.price2)}</span>
                <br/><br/>
                <span><b>Room Occupants:</b> {props.summary.occupants} </span>
                <br/><br/>
                <span><b>Check-in Date: </b> {props.summary.startDate == null ? '' : props.summary.startDate }</span>
                <br/><br/>
                <span><b>Check-in Time: </b>{props.summary.startDate2 == null ? '' : props.summary.startDate2}</span>
                <br/><br/>
                <span><b>Checkout Date: </b> {props.summary.endDate}</span>
                <br/>
                <br/>
                <span><b>Optional Services: </b> {props.summary.options === null ? 0 : props.summary.options.length}</span>
                <br/>
                <br/>
                <span><b>Transportation: </b> {props.summary.transport === null ? '' : props.summary.transport.name +"- "+Naira.format(props.summary.transport.price)}</span>
                {/* <span><b>Final Price: </b> {Naira.format(finalPrice)}</span> */}
                <br/><br/>
                <small><b>NOTE:</b> Each guest is entitled to breakfast daily. Other meals can be ordered for and paid for at the resort and will be delivered on request.</small>
                        </div> : <div className="booking-sub-section d-block d-sm-none d-md-none d-lg-none d-xl-none">
    <br/>
            <h5><b>BOOKING SUMMARY</b></h5>
                <br/>
                <span><b>Room Name:</b> {props.summary.category}</span>
                <br/><br/>
                <span><b>Day Time Price:</b> {props.summary.price.price2 == null ? 0 : Naira.format(props.summary.price.price2)}</span>
                <br/><br/>
                <span><b>Overnight Price:</b> {props.summary.price.price2 == null ? 0 : Naira.format(props.summary.price.price2)}</span>
                <br/><br/>
                <span><b>Room Occupants:</b> {props.summary.occupants} </span>
                <br/><br/>
                <span><b>Checkin Date: </b> {props.summary.startDate == null ? '' : props.summary.startDate }</span>
                <br/><br/>
                <span><b>Check-in Time: </b>{props.summary.startDate2 == null ? '' : props.summary.startDate2}</span>
                <br/><br/>
                <span><b>Checkout Date: </b> {props.summary.endDate}</span>
                <br/>
                <br/>
                <span><b>Optional Services: </b> {props.summary.options === null ? '' :props.summary.options.length}</span>
                <br/>
                <br/>
                <span><b>Final Price: </b> {isNaN(Naira.format(props.summary.price)) ? Naira.format(0) : Naira.format(props.summary.price)}</span>
                <br/><br/>
                <small><b>NOTE:</b> Each guest is entitled to breakfast daily. Other meals can be ordered for and paid for at the resort and will be delivered on request.</small>
                <br/><br/>
              
            </div>}
    </div>
}