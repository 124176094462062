import React,{useEffect, useRef, useState} from "react"
import NavBar from "../components/NavBar"
import DatePicker from "react-datepicker";
import { getRequest, postRequest } from "../services/requests";
import cogoToast from "cogo-toast";
import Footer from "../components/footer";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment/moment';
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const VisitYolo = () =>{
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(null);
    const [quantity,setQuantity] = useState(1)
    const [packages,setPackages] = useState(null)
    const [transportation,setTransportation] = useState(null)
    const MySwal = withReactContent(Swal)
    const [reservationInProgress,setReservationInProgress] = useState(null)
    const bookRef = useRef(null)
    const bookLoader = useRef(null)
    const [selectedPackage,setSelectedPackage] = useState(null)

    useEffect(() =>{
        fetchPackages()
        fetchTransportation()
    },[])
    
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        // publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
        publicKey:"pk_test_afc865879c0d28f2881172e8e18fb55d5170299b"
    })

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])
    
    const [show, setShow] = useState(false);

    const handleClose = () =>{
        setShow(false)
        setSelectedPackage(null)
    }
    const handleShow = (e) => {
        setShow(true);
        let packageId = e.target.getAttribute('package-id')
        setSelectedPackage(packageId)
    } 
    const fetchPackages = async () =>{
        try {
            let fetchedPackages = await getRequest('fetch-visit-packages')
            if(fetchedPackages.data.status){
                setPackages(fetchedPackages.data.packages)
            }else{
                cogoToast.error(fetchedPackages.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
     
    }
    const fetchTransportation = async () =>{

        try {
            let fetchedTransport = await getRequest('fetch-transportation')
            if(fetchedTransport.data.status){
                setTransportation(fetchedTransport.data.transport)
            }else{
                cogoToast.error(fetchedTransport.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
     
    }
    const bookVisit = async (e) =>{
        e.preventDefault()
        let finalData = {
            'firstName':e.target.firstname.value,
            'lastName':e.target.lastname.value,
            'phoneNumber':e.target.phoneNumber.value,
            'emailAddress':e.target.emailAddress.value,
            'code':e.target.code.value,
            'date':startDate,
            'package':selectedPackage,
            'numberOfPeople':quantity,
            'transportation':e.target.transportation.value
        }

        if(!finalData.firstName.trim()){
            cogoToast.error("Input Firstname")
        }else if(!finalData.lastName.trim()){
            cogoToast.error("Input Lastname")
        }else if(!finalData.phoneNumber.trim()){
            cogoToast.error('Input a phone number')
        }else if(!finalData.emailAddress.trim()){
            cogoToast.error('Input an email address')
        }else if(!finalData.date){
            cogoToast.error('Select a visit date')
        }else if(!finalData.package || finalData.package == 0){   
            cogoToast.error('Select a visit package')
        }else if(!finalData.numberOfPeople){
            cogoToast.error('Select number of visitors')
        }else{
                let finalPrice = 0
                let boat = 0
                let packageDetails = null
                let boatPrice = 0
                if(finalData.transportation !== 0){
                    transportation.forEach((element)=>{
                        if(element.id == finalData.transportation){
                            boatPrice = element.price
                        }
                    })
                }

                packages.forEach(element => {
                    if(element.id == finalData.package){
                        if(finalData.transportation !== 0){
                            boat = boatPrice * finalData.numberOfPeople
                        }
                        let price = element.price * finalData.numberOfPeople + boat
                        finalPrice = price
                        packageDetails = element.package
                    }
                });
                //setShow(false)
               MySwal.fire({
                title: <p></p>,
                html:`<span><b>Fist name:</b>${finalData.firstName}</span><br/><br/><span><b>Last Name:</b>${finalData.lastName}</span><br/><br/><span><b>Email Address: </b>${finalData.emailAddress}</span><br/><span><br/><span><b>phone Number: </b>${finalData.phoneNumber}</span><br/><br/>
                <span><b>Selected Package: </b>${packageDetails}</span><br/><br/>
                <b>Transportation:</b> ${Naira.format(boat)} <br/><br/>
                <b>Visiting Date:</b> ${moment(finalData.date).format("DD/MM/YYYY")} <br/><br/>
                <b>Booking Fee: </b> ${Naira.format(finalPrice * 0.10)}
                <br/><br/>
                <b>Total Price: </b> ${Naira.format(finalPrice + finalPrice * 0.10)}`,
                showCancelButton: true,
                confirmButtonColor: '#1d4028',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Pay!',
                didOpen: () => {

                },
              }).then( async (response)=>{
                if(response.isConfirmed){
                    try {
                        bookRef.current.style.display = "none"
                        bookLoader.current.style.display = 'block'
                        let book = await postRequest('book-visit',finalData)
                        if(book.data.status){
                            bookRef.current.style.display = "block"
                        bookLoader.current.style.display = 'none'
                            setPaymentConfig(paymentConfig =>({
                                    ...paymentConfig,
                                    ...{'email': book.data.paymentDetails.email,
                                    'amount': book.data.paymentDetails.price,'reference':book.data.paymentDetails.paymentRef   
                                    }
                            }))
                            setReservationInProgress(book.data.data)
                        }else{
                            bookRef.current.style.display = "block"
                        bookLoader.current.style.display = 'none'
                         cogoToast.error(book.data.message)
                        } 
                    } catch (error) {
                        bookRef.current.style.display = "block"
                        bookLoader.current.style.display = 'none'
                    }
                }else{
                    
                }
              })   
            
        }
    }
    
    const onSuccess = (reference) =>{
        bookRef.current.style.display = "none"
        bookLoader.current.style.display = 'block'
        postRequest('finalize-visit-reservation',{reference:reference,"bookingDetails":reservationInProgress}).then((response)=>{
            if(response.data.status === true){
                bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'false'
                Swal.fire({
                    icon: 'success',
                    title: 'Reservation Complete',
                    text: 'Your reservation has been made successfully, please check your email address for more details.',
                  }).then((response)=>{
                      if(response.isConfirmed){
                        navigate("/")
                      }
                  })
            }else{
                bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'false'
                cogoToast.error(response.data.message,{
                    hideAfter:15
                })
            }
        }).catch((error )=>{
            bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'false'
            cogoToast.success(error.response.data.message,{
                position:"bottom-right"
        })
        })
    }
    const initializePayment = usePaystackPayment(paymentConfig);
    const onClose = () => {
        console.log("closed");
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    const increase = () =>{
        setQuantity(quantity + 1)
    }
    const decrease = () =>{
        if(quantity > 1){
            setQuantity(quantity - 1)
        }
    }
    return (
        <React.Fragment>
             <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">
            <NavBar/>
          </div>
            </div>
            <div id="visit-main-div">
            <div className="row g-0">
                <div className="col-md-1">

                </div>
            <div className="col-md-10">
            <br/><br/><br/>
             <div className="visit-div">
                <div className="row">
                    <div className="col-md-12">
                    <br/><br/>
                    <div className="row">
                    <div className="col-md-4">

                        </div>
                        <div className="col-md-4">
                        {/* <h3 style={{'textAlign':'center'}} className="d-block d-sm-block d-md-none">Visit Yolo</h3> */}
                        <h1 style={{'textAlign':'center'}}>Visit Yolo, Day Pass</h1>
                        <div style={{"width":"100%","borderRadius":"7px","height":"250px","margin":"auto","marginTop":"200px","backgroundColor":"white","padding":"10px"}} className="visit-init">
                      <h3>GATE FEE {packages !== null ? Naira.format(packages[0].price) : "##"}</h3>
                      <hr/>
                      <p>
                        <b>Transportation available for 10 guests and above at {packages !== null ? Naira.format(packages[0].price) : "##"} per person.</b>
                        <br/>
                        <b>Variety of food and drinks available for purchase at YOLO restaurant and bar. No external food and drinks allowed.</b>
                      </p>
                        <button className="book-button" style={{'marginLeft':'0'}} package-id={packages !== null ? packages[0].id : 0} onClick={handleShow}>
                            Proceed
                        </button>
             {/* <div className="visit-option-box" style={{"backgroundColor":"#ffd959"}} package-id={packages !== null ? packages[0].id : 0} onClick={handleShow}>
                                        
                                        <div className="sub-visit-options"  package-id={packages !== null ? packages[0].id : 0} onClick={handleShow} style={{"backgroundColor":"#006266"}}>
                                        {packages !== null ? <p className="package-detail-price" package-id={packages !== null ? packages[0].id : 0} onClick={handleShow} style={{"color":"white"}}>{packages[0].price.slice(0, 2)}K</p> : <p>Loading.........</p>}   
                                        </div>  
                                        {packages !== null ? <p className="package-detail"  package-id={packages !== null ? packages[0].id : 0} onClick={handleShow}>{packages[0].package}</p> : <p>Loading.........</p>}
                                    </div> */}
                        </div>  
                        </div>
                        <div className="col-md-4">

                        </div>
                    </div>
                 
                    </div>
                </div>
             
             
                    {/* <div className="row">
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-6">
                            <div className="row g-1 inner-option-container" style={{marginTop:"330px"}}>
                            <h3 style={{'textAlign':'center'}} className="d-block d-sm-block d-md-none">VIsit Yolo</h3>
                            <hr className="d-block d-sm-block d-md-none"/>
                                <div className="col-6">
                                    
                                </div> */}
                                {/* <div className="col-6">
                                <div className="visit-option-box"  style={{"backgroundColor":"#ffd959"}} onClick={handleShow} package-id={packages !== null ? packages[1].id : 0}>
                                <div className="sub-visit-options"  package-id={packages !== null ? packages[1].id : 0} onClick={handleShow} style={{"backgroundColor":"#006266"}}>
                                {packages !== null ? <p className="package-detail-price"  package-id={packages !== null ? packages[1].id : 0} onClick={handleShow} style={{"color":"white"}}>{packages[1].price.slice(0, 2)}K</p> : <p>Loading.........</p>}   
                                </div>
                                {packages !== null ? <p className="package-detail"  package-id={packages !== null ? packages[1].id : 0} onClick={handleShow}>{packages[1].package}</p> : <p>Loading.........</p>}
                                    </div>
                            </div> */}
                           
                            {/* </div> */}
                            {/* <div className="row g-0">
                                <div className="col-6">
                                <div className="visit-option-box" style={{"backgroundColor":"#006266"}} onClick={handleShow} package-id={packages !== null ? packages[2].id : 0}>
                                <div className="sub-visit-options"  package-id={packages !== null ? packages[2].id : 0} onClick={handleShow} style={{"backgroundColor":"#ffd959"}}>
                                {packages !== null ? <p className="package-detail-price"  package-id={packages !== null ? packages[2].id : 0} onClick={handleShow} style={{"color":"white"}}>{packages[2].price.slice(0, 2)}K</p> : <p>Loading.........</p>}   
                                    </div>
                                    {packages !== null ? <p className="package-detail"  package-id={packages !== null ? packages[2].id : 0} onClick={handleShow} style={{"color":"#ffd959"}}>{packages[2].package}</p> : <p>Loading.........</p>}
                                    </div>
                                </div>
                                <div className="col-6">
                                <div className="visit-option-box" style={{"backgroundColor":"#006266"}} onClick={handleShow} package-id={packages !== null ? packages[3].id : 0}>
                                <div className="sub-visit-options"  package-id={packages !== null ? packages[3].id : 0} onClick={handleShow} style={{"backgroundColor":"#ffd959"}}>
                                {packages !== null ? <p className="package-detail-price"  package-id={packages !== null ? packages[3].id : 0} onClick={handleShow} style={{"color":"white"}}>{packages[3].price.slice(0, 2)}K</p> : <p>Loading.........</p>}   
                                </div>
                                {packages !== null ? <p className="package-detail"  package-id={packages !== null ? packages[3].id : 0} onClick={handleShow} style={{"color":"#ffd959"}}>{packages[3].package}</p> : <p>Loading.........</p>}
                                </div>
                            </div>
                            </div> */}
                        {/* </div>
                        <div className="col-md-3">

                        </div>
                    </div> */}
                    {/* <div style={{'width':'100%'}}>
                    <h4>Visit Yolo Island</h4>
                    <form onSubmit={bookVisit}>
                        <input placeholder="Firstname" name="firstname" className="form-control"/><br/>
                        <input placeholder="Lastname" name="lastname" className="form-control"/><br/>
                        <input placeholder="Email Address" name="emailAddress" className="form-control"/><br/>
                        <input placeholder="Phone Number" name="phoneNumber" className="form-control"/><br/>
                        <div className="form-check" style={{'marginBottom':'20px'}}>
                        <input className="form-check-input" name="extra" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" for="flexCheckDefault">
                            Do you want to bring in extra food and drinks?
                        </label>
                        </div>
                        
                        <i className="bi bi-plus-square" style={{'cursor':'pointer'}} onClick={increase}></i><span> {quantity} </span><i style={{'cursor':'pointer'}} className="bi bi-dash-square" onClick={decrease}></i> <small>Number of people</small>
                        <br/><br/>
                        <input placeholder="Agency or membership code" name="code" className="form-control"/><br/>
                        <DatePicker
                     selected={startDate}
                      className="form-control"
                      onChange={(date) => setStartDate(date)}
                      dateFormat="d MMMM, yyyy h:mm aa"
                      timeFormat="HH:mm"
                      minDate={Date.now()}
                      timeIntervals={15}
                      timeCaption="time"
                      showTimeSelect
                      placeholderText="Date of visit"
                    />
                    <br/><br/>
                    <select className="form-select" name="package">
                        <option value="0">Select Package</option>
                        {packages !== null ? 
                        packages.map((item) =>{
                            return <option value={item.id}>{item.package +"-"+Naira.format(item.price)}</option>
                        })    
                    : ''}
                    </select>
                    <br/>
                    <button className='small-button' ref={bookRef}>Proceed</button>
                                <div className='spinner-border' role='status' ref={bookLoader} style={{"display":"none"}}></div>
                    </form>
                    </div> */}
                 
                </div> 
            </div>
            <div className="col-md-1">

                </div>
            </div>
            </div>
            </div>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Visit Yolo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={bookVisit}>
                        <input placeholder="Firstname" name="firstname" className="form-control"/><br/>
                        <input placeholder="Lastname" name="lastname" className="form-control"/><br/>
                        <input placeholder="Email Address" name="emailAddress" className="form-control"/><br/>
                        <input placeholder="Phone Number" name="phoneNumber" className="form-control"/><br/>
                        
                        <i style={{'cursor':'pointer','fontSize':'25px'}} className="bi bi-dash-square" onClick={decrease}></i><span style={{'cursor':'pointer','fontSize':'25px'}}> {quantity} </span> <i className="bi bi-plus-square" style={{'cursor':'pointer','fontSize':'25px'}} onClick={increase}></i> <small>Number of people</small>
                        <br/><br/>
                        <input placeholder="Agency or membership code" name="code" className="form-control"/><br/>
                        <DatePicker
                     selected={startDate}
                      className="form-control"
                      onChange={(date) => setStartDate(date)}
                      dateFormat="d MMMM, yyyy h:mm aa"
                      timeFormat="HH:mm"
                      minDate={Date.now()}
                      timeIntervals={15}
                      timeCaption="time"
                      showTimeSelect
                      placeholderText="Date of visit"
                    />
                    <br/><br/>
                    <small>Optional boat transportation for visitors</small>
                                <select onChange={transportation} name="transportation" className="form-select">
                                    <option value={0}>Select Boat Transportation</option>
                                {
                                transportation !== null ? transportation.map((item)=>{
                                    return <option key={item.id} id={item.id} value={item.id}>{item.name+"-"+Naira.format(item.price)}</option>
                                }) : ''
                            }
                                </select>
                                <br/>
                    <button className='small-button' ref={bookRef}>Proceed</button>
                                <div className='spinner-border' role='status' ref={bookLoader} style={{"display":"none"}}></div>
                    </form>
        </Modal.Body>
      </Modal>
            <br/><br/><br/><br/><br/>
            <Footer />
        </React.Fragment>
    )
}

export default VisitYolo